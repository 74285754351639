import Vue from "vue";
import "@mdi/font/css/materialdesignicons.css";
import Buefy from "buefy";
import axios from "axios";
import VueLogger from "vuejs-logger";
import VueKonva from "vue-konva";
// import "buefy/dist/buefy.css";
import "./scss/global.scss";
import App from "./App.vue";
import router from "./router";
import { default as store, resetState } from "./store";
import i18n, { loadLanguageAsync } from "./i18n";
import "./vee-validate";
import config from "./config";

import { ToastProgrammatic as Toast } from "buefy";

import hljs from "highlight.js/lib/core";
import xml from "highlight.js/lib/languages/xml";
hljs.registerLanguage("xml", xml);
// hljs.initHighlightingOnLoad();
import "highlight.js/styles/vs.css";
import { policiesService } from "./services";

const isProduction = process.env.NODE_ENV === "production";

const loggerOptions = {
  isEnabled: true,
  logLevel: isProduction ? "error" : "debug",
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: true,
  separator: "|",
  showConsoleColors: true,
};

if (!isProduction) {
  axios.interceptors.request.use((request) => {
    console.log("Starting Request", request);
    return request;
  });
  axios.interceptors.response.use(
    (response) => {
      console.log("Response", response);
      return Promise.resolve(response);
    },
    (error) => {
      return Promise.reject(error);
    }
  );
}

axios.interceptors.response.use(
  (response) => {
    return Promise.resolve(response);
  },
  (error) => {
    const { status } = error.response;
    if (status === 401) {
      Toast.open({
        message: i18n.t("error.401-message"),
        type: "is-danger",
      });
      console.log(router.currentRoute.fullPath);
      resetState();
      router.push({
        name: "Login",
        // query: { redirect: router.currentRoute.fullPath },
      });
      // store.dispatch("users/logout").then(
      //   router.push({
      //     name: "Login",
      //     query: { redirect: router.currentRoute.fullPath },
      //   })
      // );
    }
    if (status === 500) {
      Toast.open({
        message: i18n.t("error.500-message"),
        type: "is-danger",
        duration: 4000,
      });
    }
    if (status === 550) {
      Toast.open({
        message: error.response.data.message,
        type: "is-danger",
        duration: 4000,
      });
    }
    return Promise.reject(error);
  }
);

Vue.use(VueLogger, loggerOptions);

Vue.use(Buefy);

Vue.use(VueKonva);

Vue.use(hljs.vuePlugin);

Vue.config.productionTip = false;

fetch(process.env.BASE_URL + "config.json")
  .then((response) => response.json())
  .then((json) => {
    config.properties = json;
    if (config.properties.title) {
      document.title = config.properties.title;
    }
    Vue.prototype.$config = config.properties;

    axios.defaults.baseURL = config.properties.urls.DOMAIN;

    policiesService.loadConfig();
  })
  .then(() => {
    loadLanguageAsync().then(() => {
      new Vue({
        router,
        store,
        i18n,
        render: (h) => h(App),
      }).$mount("#app");
    });
  })
  .catch((error) => {
    console.log(error);
  });
